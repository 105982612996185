<template>
	<div class="page">
		<el-row class="tool-bar">
			<el-col :span="12">
				<search-tool v-model="search" @input="$utils('search', getBusStatus)"/>
			</el-col>
			<el-col :span="12" align="right">
				<el-button type="success" icon="el-icon-plus" @click="$utils('goTo', '/bus-status/create')" v-if="$utils('roleUser', 'Bus_status', 'create')">{{ $t('global.novo') }}</el-button>
			</el-col>
		</el-row>
		<el-row>
			<!--Table-->
			<el-table v-if="busStatus.length"
						:data="busStatus"
						ref="table"
						:default-sort="sortBy"
						v-loading="gridLoad"
						border
						stripe>
				<el-table-column
						align="left"
						prop="linija"
						:label="$t('busStatus.linija')">
				</el-table-column>
				<el-table-column
						width="150"
						align="left"
						prop="drzava"
						:label="$t('busStatus.drzava')">
				</el-table-column>
				<el-table-column
						align="left"
						prop="opis"
						:label="$t('busStatus.opis')">
				</el-table-column>
				<el-table-column
						width="150"
						align="left"
						prop="mobilni_vozaca"
						:label="$t('busStatus.mobilniVozaca')">
				</el-table-column>
				<el-table-column
						width="130"
						align="center"
						prop="gotovo"
						:label="$t('busStatus.gotovo')">
					<template slot-scope="scope">
						<i v-if="scope.row.gotovo === 0" class="fas fa-times-circle color-text--red"></i>
						<i v-else-if="scope.row.gotovo === 1" class="fas fa-check-circle color-text--green"></i>
					</template>
				</el-table-column>
				<el-table-column
						align="left"
						prop="mesto_sledece"
						:label="$t('busStatus.mestoSledece')">
					<template slot-scope="scope">
						{{scope.row.mesto_sledece}} - {{scope.row.vreme_sledece}}
					</template>
				</el-table-column>
				<el-table-column
						align="center"
						prop="aktivno"
						:label="$t('busStatus.aktivno')"
						width="120">
					<template slot-scope="scope">
						<el-button size="mini" @click="statusUpdate(scope.row.id)">
							<i v-if="scope.row.aktivno === 'NE'" class="fas fa-times-circle color-text--red"></i>
							<i v-else-if="scope.row.aktivno === 'DA'" class="fas fa-check-circle color-text--green"></i>
						</el-button>
					</template>
				</el-table-column>
				<el-table-column
						width="120">
					<template slot-scope="scope">
						<el-tooltip class="item" effect="dark" :content="$t('global.izmena')" placement="top" v-if="$utils('roleUser', 'Bus_status', 'edit')">
							<el-button type="success" icon="fas fa-pen" @click="$utils('goTo', '/bus-status/' + scope.row.id + '')" size="small"></el-button>
						</el-tooltip>
						<el-tooltip class="item" effect="dark" :content="$t('global.brisanje')" placement="top" v-if="$utils('roleUser', 'Bus_status', 'delete')">
							<el-button type="danger" icon="fas fa-trash" @click="$delete('busStatus', scope.row.id)" size="small"></el-button>
						</el-tooltip>
					</template>
				</el-table-column>
			</el-table>
			<el-alert v-else :title="$t('global.nemaPodataka')" type="warning" :closable="false"/>
		</el-row>
		<br>
		<el-row>
			<pagination v-model="pagination" @input="getBusStatus" ref="pagination"/>
		</el-row>
	</div>
</template>

<script>
export default {
	name: 'bus-status-list',
	data() {
		return {
			busStatus: [],
			gridLoad: false,
			pagination: {},
			search: '',
			sortBy: {
				prop: 'id',
				order: 'descending'
			}
		};
	},
	mounted() {
		this.$store.dispatch('setHeader', {
			title: this.$t('busStatus.busStatus')
		});

		this.$utils('autoLoad');
		this.$utils('stopLoadingAfter', [this.getBusStatus()]);
	},
	methods: {
		getBusStatus() {
			this.gridLoad = true;
			return this.$get('busStatus', this.$utils('getParams')).then(data => {
				this.busStatus = data.content;

				this.busStatus.map(b => {
					if (b.pravac === 'kaSRB') {
						let linija = b.linija.split('-');
						b.linija = linija[1] + ' - ' + linija[0];
					}
				});

				this.$utils('setPag', data);
				this.$utils('autoSave');
				this.gridLoad = false;
			});
		},
		statusUpdate(id) {
			this.$confirm(this.$t('confirm.opisPromeniStatus'), this.$t('confirm.upozorenje'), {
				confirmButtonText: this.$t('global.uredu'),
				cancelButtonText: this.$t('global.odustani'),
				type: 'warning'
			}).then(() => {
				this.gridLoad = true;
				this.$put('busStatusStatus', { id: id }, {}).then(() => {
					this.getBusStatus();
				}).catch(() => {
					this.gridLoad = false;
				});
			});
		}
	}
};
</script>
